<script>

import VueGoogleAutocomplete from 'vue-google-autocomplete'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

const getCountryISO3 = require("country-iso-2-to-3");

export default {
  components:{
    Loading,
    VueGoogleAutocomplete
  },
  props: {
    bookingID: Number,
    activeStep: Number,
    totalSteps: Number
  },
  data(){
    return {
      isLoading: false,
      location: '',
      latitude: '',
      longitude: '',
      address: '',
      country_id: '',
      city: '',
      countries: [],
      error: null,
      expand_search: true
    }
  },
  computed: {
    bookingProgress() {
      return this.activeStep * 100 / this.totalSteps
    }
  },
  mounted() {
    this.isLoading = true
    this.$axios.get(`/v3/event_bookings/${this.bookingID}`)
        .then(response => {
          this.location = response.data.location || ''
          this.address = response.data.address || ''
          this.country_id = response.data.country_id
          this.latitude = response.data.latitude
          this.longitude = response.data.longitude
          this.city_id = response.data.city_id
          this.isLoading = false
          this.expand_search = response.data.expand_search
          window.initMap()
        })
        .catch(error => {
          this.isLoading = false
        })
    this.$axios.get('/v3/locations')
        .then(response => {
          this.countries = response.data
        })
  },
  methods: {
    getAddressData: function (addressData, placeResultData, id) {
      const address_components = placeResultData.address_components
      const address_country = address_components.find(address => address.types.includes('country'))
      if (address_country) {
        const alpha2 = address_country.short_name
        const alpha3 = getCountryISO3(alpha2)
        const country_data = this.countries.find(country => country.slug === alpha3.toLowerCase())
        if (country_data) {
          this.country_id = country_data.id
        }
      }
      this.latitude = placeResultData.geometry.location.lat()
      this.longitude = placeResultData.geometry.location.lng()
      this.city = addressData.locality
      this.location = this.$refs.map.$el.value
      window.initMap()
    },
    bookingParams() {
      return {
        booking: {
          location: this.location,
          address: this.address,
          latitude: this.latitude,
          longitude: this.longitude,
          country_id: this.country_id,
          city: this.city,
          expand_search: this.expand_search,
          created_by_mobile: false
        }
      }
    },
    nextStep() {
      this.$emit('updateStep', { booking_params: this.bookingParams(), next_step: 'select_time' })
    },
    back() {
      this.$emit('updateStep', { booking_params: this.bookingParams(), next_step: 'select_date' })
    },
  },
}
</script>

<template>
  <div class="new-booking__art-type new-booking__content">
    <div class="vld-parent">
      <loading :active.sync="isLoading"
               :can-cancel="false"
               :is-full-page="true"
               color="#FF8141"
      ></loading>
    </div>

    <p class="mt-4 mb-5">
      Providing an exact address or venue will give you more accurate options.
      (select closest location from suggestions)
    </p>

    <div class="new-booking__content">
      <p class="mb-1">Select event location</p>
      <vue-google-autocomplete
          id="map"
          ref="map"
          classname="input order-capture-input border-0"
          :value="location"
          placeholder="Select event location"
          v-on:placechanged="getAddressData"
          :types="['geocode', 'establishment']">
      </vue-google-autocomplete>

      <div
          v-if="latitude && longitude"
          class="mt-2 mb-4 google_map"
          :data-latitude="`${parseFloat(latitude)}`"
          :data-longitude="`${parseFloat(longitude)}`"
          style="height: 165px;">
      </div>

      <p class="mb-2">Where is the exact location?</p>
      <input
          v-model="address"
          type="text"
          :required="true"
          class="input order-capture-input border-0"
          v-bind:class="{ 'error': error}"
          placeholder="Exact location">
      <span v-if="error" class="error-message">{{ error? error.toString() : null }}</span>

      <h4>Expand Search</h4>
      <p class="mb-4">Search for artists outside your city (If no artists are available locally)</p>

      <ul class="expand-search-options-container">
        <li
            :class="{ 'expand-search-selected-item': expand_search, 'expand-search-item': !expand_search }"
            @click="expand_search = true">
          Yes
          <span class="ms-auto">
            <font-awesome-icon v-if="expand_search" :icon="['fas', 'circle-check']" style="color: #ff8141;" />
            <font-awesome-icon v-else :icon="['fas', 'circle']" style="color: #dcdcdc;" />
          </span>
        </li>
        <li
            :class="{ 'expand-search-selected-item': !expand_search, 'expand-search-item': expand_search }"
            @click="expand_search = false">
          No
          <span class="ms-auto">
            <font-awesome-icon v-if="!expand_search" :icon="['fas', 'circle-check']" style="color: #ff8141;" />
            <font-awesome-icon v-else :icon="['fas', 'circle']" style="color: #dcdcdc;" />
          </span>
        </li>
      </ul>

      <div class="mt-5">
        <v-app class="w-100 mb-4">
          <p class="mb-1">Step {{ activeStep }} of {{ totalSteps }}</p>
          <v-progress-linear
              rounded
              :value="bookingProgress"
              height="8">
          </v-progress-linear>
        </v-app>

        <div class="back-next">
          <button
              class="settings-forms__btn btn secondary fluid"
              @click="back()">
            Back
          </button>
          <button
              class="settings-forms__btn btn primary fluid"
              @click="nextStep()"
              :disabled="!location || !address">
            Next
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
</style>
